"use client";
import Carousel from "@/components/Carousel";

const Instagram = () => {
  return (
    <div>
      <section className="bg-white py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 gap-3 md:gap-6 lg:gap-10 items-center justify-between text-center">
            <div>
              <p className="font-semibold text-yellow-500 text-base md:text-lg uppercase">
                Our Instagram
              </p>
              <h2 className="font-bold text-black my-3">@Kova_Luxury_Stay</h2>
              <Carousel />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Instagram;
