"use client";
import React from "react";
import Slider from "react-slick";

// Import Slick styles (required for the slider to work)
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Sample images (replace with your actual images)
const images = [
  "/images/luxury-service-apartments/omkar-alta-monte-malad/Designer-Bedroom-Accents.jpg",
  "/images/luxury-service-apartments/omkar-alta-monte-malad/guest-house-1.jpeg",
  "/images/luxury-service-apartments/omkar-alta-monte-malad/Modern-Living-Area.jpg",
  // "/images/service1.jpg",
];

const CustomCarousel = () => {
  const settings = {
    dots: true, // Enable pagination dots
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Number of images to show at once
    slidesToScroll: 1,
    autoplay: true, // Autoplay the slider
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true, // Show next/prev arrows
    responsive: [
      {
        breakpoint: 1024, // For medium screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For small mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="max-w-screen-xl mx-auto py-8">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <div className="relative group">
              <img
                src={image}
                alt={`Slide ${index}`}
                className="w-full h-64 object-cover rounded-lg"
              />
              {/* Instagram Icon Overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-25 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {/* <img
                  src="/images/instagram-icon.png" // Replace with your Instagram icon
                  alt="Instagram"
                  className="w-10 h-10"
                /> */}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomCarousel;
