"use client";
import Image from "next/image";
import { useState } from "react";

const OverlayCard = ({ title, description, imageSrc, link }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="relative group overflow-hidden cursor-pointer"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="relative w-full h-72">
        <Image
          src={imageSrc}
          alt={title}
          fill
          className="transition-transform duration-500 transform group-hover:scale-105"
        />
      </div>

      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>

      {/* Title and Description */}
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center">
        <div className="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-50 text-center">
          <h3 className="text-xl uppercase font-bold text-white">{title}</h3>
        </div>

        {hover && description && (
          <div className="opacity-100 transition-opacity duration-500">
            <p className="px-4 text-white text-lg mb-4">{description}</p>
            <a href={link}></a>
          </div>
        )}
      </div>
    </div>
  );
};

const OverlayCardsGrid = () => {
  const cardsData = [
    {
      title: "Serviced",
      imageSrc: "/images/luxury-hotel-service.jpg",
      description:
        "Weekly housekeeping, linen changes, free internet, and local support included. Extra services available on request.",
    },
    {
      title: "Self Check-In",
      description:
        "24/7 self check-in with a code sent via email. No key worries—access anytime, hassle-free.",
      imageSrc: "/images/self-check.jpg",
    },
    {
      title: "Furnished & Equipped",
      description:
        "Fully furnished apartments with quality materials and stylish interiors, available in various sizes to suit your needs.",
      imageSrc: "/images/furnished-and-equipped.jpg",
    },
  ];

  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-3">
      {cardsData.map((card, index) => (
        <OverlayCard
          key={index}
          title={card.title}
          description={card.description}
          imageSrc={card.imageSrc}
          link={card.link}
        />
      ))}
    </div>
  );
};

export default OverlayCardsGrid;
