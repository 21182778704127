"use client";
import { useState } from "react";
import Link from "next/link";

const HeroSection2 = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleImageClick = (index) => {
    setClickedIndex(clickedIndex === index ? null : index); // Toggle image expansion
  };

  const images = [
    {
      src: "/images/sliders/luxurious-hotels.jpg",
      label: "Premium Hotels",
      url: "/rental/luxurious-hotels", // URL for this image
    },
    {
      src: "/images/sliders/Master-Bedroom-City-View.jpg",
      label: "Luxury Service Apartments",
      url: "/rental/luxury-service-apartments", // URL for this image
    },
    {
      src: "/images/sliders/guest-house.jpeg",
      label: "Guest House",
      url: "/rental/guest-house", // URL for this image
    },
    {
      src: "/images/sliders/premium-service-apartments.jpg",
      label: "Luxury Villas",
      url: "/rental/luxury-villas", // URL for this image
    },
  ];

  return (
    <>
      <div className="hidden md:block">
        <div className="w-full flex h-screen overflow-hidden">
          {images.map((image, index) => (
            <div
              key={index}
              className={`relative flex transition-all duration-[1500ms] ease-in-out overflow-hidden ${
                hoveredIndex === index ? "flex-[4]" : "flex-[2]"
              }`}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <Link href={image.url}>
                <img
                  src={image.src}
                  alt={image.label}
                  className="w-full h-full border-x-[1px] border-white object-cover transition-transform duration-[1500ms] ease-in-out"
                />
                {/* Gradient shadow at the bottom */}
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-90 hover:opacity-10"></div>

                {/* Logo and Title centered at the bottom */}
                <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 w-full herologo text-white p-4">
                  <img
                    src="/images/logo.png"
                    alt="Client Logo"
                    className="h-12 mb-2 mx-auto"
                  />
                  <span className="text-2xl font-normal">{image.label}</span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* Mobile Section */}
      <div className="w-full h-full mt-28 overflow-hidden block md:hidden">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative flex transition-all duration-[1500ms] ease-in-out overflow-hidden flex-col h-56"
          >
            <Link href={image.url}>
              <img
                src={image.src}
                alt={image.label}
                className="w-full h-full border-x-[1px] border-white object-cover transition-transform duration-[1500ms] ease-in-out"
              />
              {/* Gradient shadow at the bottom */}
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-90"></div>

              {/* Logo and Title centered at the bottom */}
              <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 w-full herologo text-white p-4">
                <img
                  src="/images/logo.png"
                  alt="Client Logo"
                  className="h-12 mb-2 mx-auto"
                />
                <span className="text-2xl font-normal">{image.label}</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default HeroSection2;
