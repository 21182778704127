"use client";
import Image from "next/image";
import SectionWithImage from "@/components/SectionWithImage";
import Link from "next/link";

const LatestRentals = () => {
  return (
    <>
      <div>
        <SectionWithImage
          imageLeftPosition={true}
          imageSrc="/images/luxury-service-apartments/omkar-alta-monte-malad/Living-Room-with-Balcony-Access.jpg"
          imageAlt="Luxury Service Apartment"
        >
          <h2 className="text-3xl font-bold text-gray-800">
            Omkar Alta Monte Malad - Luxury Service Apartments
          </h2>
          <p className="text-gray-700 mb-2">
            Welcome to Omkar Alta Monte, where luxury meets convenience in our
            exclusive service apartments.
          </p>
          <p className="text-gray-700 mb-2">
            Our premium rental apartments are thoughtfully designed to deliver
            an unmatched living experience, seamlessly blending elegance,
            comfort, and exceptional service. Whether you're in town for
            business or leisure, our apartments cater to your every need.
          </p>
          <p className="text-gray-700 mb-2">
            Each unit is tastefully furnished with sophisticated interiors,
            featuring modern amenities such as high-speed Wi-Fi, plush bedding,
            flat-screen TVs, and fully equipped kitchens. At Omkar Alta Monte,
            we aim to make your stay not just comfortable, but truly
            extraordinary.
          </p>
          <div className="mt-4">
            <Link
              href="/rental/luxury-service-apartments/omkar-alta-monte-malad"
              className="inline-block bg-yellow-500 text-white font-medium text-lg px-6 py-3 rounded-lg shadow-md hover:bg-yellow-600 transition duration-300"
            >
              Read More
            </Link>
          </div>
        </SectionWithImage>
      </div>

      <div>
        <SectionWithImage
          imageLeftPosition={false}
          imageSrc="/images/luxury-service-apartments/omkar-signet-malad-east/bedroom-modern-interior-design.jpg"
          imageAlt="Luxury Service Apartment"
        >
          <h2 className="text-3xl font-bold text-gray-800">
            Omkar Signet Malad East - Luxury Apartments for Rental
          </h2>
          <p className="text-gray-700 mb-2">
            Welcome to Omkar Signet, Malad East, where sophistication and
            comfort come together in our luxurious rental apartments.
          </p>
          <p className="text-gray-700 mb-2">
            Our luxury apartments are meticulously crafted to offer a serene and
            elegant living environment, perfect for both short-term stays and
            long-term rentals. Designed with modern aesthetics and equipped with
            premium amenities, Omkar Signet ensures an exceptional lifestyle.
          </p>
          <p className="text-gray-700 mb-2">
            Each apartment features contemporary interiors, high-speed Wi-Fi,
            spacious layouts, flat-screen TVs, and premium bedding to provide a
            home-away-from-home experience. Whether you're here for work or
            leisure, our apartments are tailored to meet your needs with style
            and efficiency.
          </p>
          <div className="mt-4">
            <Link
              href="/rental/luxury-service-apartments/omkar-signet-malad-east"
              className="inline-block bg-yellow-500 text-white font-medium text-lg px-6 py-3 rounded-lg shadow-md hover:bg-yellow-600 transition duration-300"
            >
              Read More
            </Link>
          </div>
        </SectionWithImage>
      </div>

      <div>
        <SectionWithImage
          imageLeftPosition={true}
          imageSrc="/images/guest-house/andheri-west/guest-house-5.jpeg"
          imageAlt="Luxury Service Apartment"
        >
          <h2 className="text-3xl font-bold text-gray-800">
            Guest House in Andheri West - Comfortable and Luxurious Stay
          </h2>
          <p className="text-gray-700 mb-2">
            Welcome to our premium guest house in Andheri West, where comfort,
            elegance, and convenience come together to offer you a memorable
            stay.
          </p>
          <p className="text-gray-700 mb-2">
            Our guest house is designed to cater to both business travelers and
            leisure guests, offering meticulously furnished rooms that combine
            modern amenities with a cozy atmosphere. Each room is equipped with
            high-speed Wi-Fi, plush bedding, air conditioning, and flat-screen
            TVs to ensure a seamless and comfortable experience.
          </p>
          <p className="text-gray-700 mb-2">
            Enjoy the convenience of our thoughtfully designed interiors,
            complete with ample space, contemporary furnishings, and functional
            layouts. Whether you're here for a short visit or an extended stay,
            our guest house provides the perfect balance of comfort and
            practicality.
          </p>
          <div className="mt-4">
            <Link
              href="/rental/guest-house/andheri-west"
              className="inline-block bg-yellow-500 text-white font-medium text-lg px-6 py-3 rounded-lg shadow-md hover:bg-yellow-600 transition duration-300"
            >
              Read More
            </Link>
          </div>
        </SectionWithImage>
      </div>
    </>
  );
};

export default LatestRentals;
