"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Statistics from "@/components/Statistics";

function AboutUs() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hide arrows if not needed, or set to true to show
  };

  const images = [
    "/images/luxury-service-apartments/omkar-alta-monte-malad/Skyline-View-from-Bedroom.jpg",
    "/images/luxury-service-apartments/omkar-alta-monte-malad/Living-Room-with-Balcony-Access.jpg",
    "/images/luxury-service-apartments/omkar-alta-monte-malad/Balcony-Lounge-View.jpg",
  ];

  const properties = 50;
  const employees = 50;
  const cities = 4;

  return (
    <div>
      <section className="bg-white flex flex-col items-center justify-center h-screen2 py-20">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between">
            <div className="lg:pr-6">
              <p className="font-normal text-colorGold-600 text-lg md:text-2xl uppercase">
                Discover Kova Luxury Stay
              </p>
              <h2 className="font-normal text-black my-3">
                Experience a rental accommodation that reflects your lifestyle
              </h2>
              <p className="mb-2">
                At <strong>Kova Luxury Stay</strong>, we offer premium
                accommodations, including premium hotel accomodations, luxury
                serviced apartments and guest rooms. Our spaces are designed to
                combine elegance with modern amenities, ensuring a comfortable
                and enjoyable stay whether you need a room for a day or an
                apartment for a month.
              </p>
              <p className="text-base md:text-lg mb-2">
                We’re more than just a place to stay—we provide a lifestyle of
                luxury and convenience. With properties in prime locations, Kova
                Luxury Stay offers the perfect blend of comfort, style, and
                affordability, catering to short and long-term stays alike.
              </p>
              <Statistics
                properties={properties}
                employees={employees}
                cities={cities}
              />
              <div className="mt-6 md:mt-8">
                <Link
                  href="/about-us"
                  className="bg-transparent hover:bg-colorGold-600 border-2 border-colorGold-500 text-black hover:text-white px-6 py-2 lg:px-8 lg:py-2"
                >
                  Read More
                </Link>
              </div>
            </div>

            {/* Slider Section */}
            <div className="relative p-4 before:absolute before:inset-10 before:border before:border-colorGrey-300 before:pointer-events-none">
              <Slider {...sliderSettings}>
                {images.map((image, index) => (
                  <div key={index}>
                    <Image
                      src={image}
                      alt={`Luxury Stay Image ${index + 1}`}
                      className="object-cover h-[40rem] rounded-xl"
                      width={955}
                      height={1000}
                      unoptimized
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
