"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";
import { Avatar, Blockquote, Rating } from "flowbite-react";

const Testimonials = () => {
  return (
    <div>
      <section
        className="bg-colorGrey-200 bg-value py-10 md:py-20 lg:py-24"
        style={{ background: 'url("/images/testimonials_bg1.png")' }}
      >
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 gap-3 md:gap-6 lg:gap-10 items-center justify-between text-center">
            <div>
              {/* <h2 className="font-bold text-yellow-500 my-3">Client</h2> */}
              <figure>
                <h3 className="font-sans font-semibold">Testimonials</h3>
                <Blockquote className="my-6 md:my-10">
                  <p className="text-lg md:text-xl lg:text-2xl font-medium text-colorGrey-900 font-serif md:leading-10">
                    "Staying at{" "}
                    <span className="font-bold">Kova Luxury Stay</span> was an
                    incredible experience! I rented one of their{" "}
                    <span className="font-bold">luxury service apartments</span>{" "}
                    for a month, and it truly felt like home. The apartment was
                    beautifully furnished, and the staff went above and beyond
                    to ensure I had everything I needed. The location was
                    perfect, with easy access to everything in the city. I
                    highly recommend{" "}
                    <span className="font-bold">Kova Luxury Stay</span> for
                    anyone looking for comfort, convenience, and a touch of
                    luxury."
                  </p>
                </Blockquote>

                <div className="mb-4 flex items-center justify-center">
                  <Rating size="xl" className="space-x-4">
                    <Rating.Star />
                    <Rating.Star />
                    <Rating.Star />
                    <Rating.Star />
                    <Rating.Star />
                  </Rating>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
