import React from "react";

const Statistics = ({ properties, employees, cities }) => {
  return (
    <div className="w-full py-10 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">
          Our Achievements
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          <div className="stat-item">
            <h3 className="text-4xl font-extrabold text-yellow-500">
              {properties}
            </h3>
            <p className="text-gray-700">Rental Properties</p>
          </div>
          <div className="stat-item">
            <h3 className="text-4xl font-extrabold text-yellow-500">
              {employees}+
            </h3>
            <p className="text-gray-700">Employees</p>
          </div>
          <div className="stat-item">
            <h3 className="text-4xl font-extrabold text-yellow-500">
              {cities}
            </h3>
            <p className="text-gray-700">City</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
