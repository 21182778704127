import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/stayinkova.com/nextjs/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/stayinkova.com/nextjs/src/app/(home)/AboutUs.jsx");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/stayinkova.com/nextjs/src/app/(home)/Instagram.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/stayinkova.com/nextjs/src/app/(home)/LatestRentals.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/stayinkova.com/nextjs/src/app/(home)/Servicecol.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/stayinkova.com/nextjs/src/app/(home)/Testimonials.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/stayinkova.com/nextjs/src/components/Card.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/stayinkova.com/nextjs/src/components/Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/stayinkova.com/nextjs/src/components/HeroSection2.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/stayinkova.com/nextjs/src/components/StickyHeaderHome.jsx");
