// components/SectionWithImage.js
import React from "react";
import Image from "next/image";

const SectionWithImage = ({
  children,
  imageSrc,
  imageAlt,
  imageLeftPosition = true,
}) => {
  return (
    <section className="relative bg-gray-50 py-16">
      <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center justify-between gap-12 px-6 lg:px-12">
        {/* Conditional Rendering for Image Position */}
        {imageLeftPosition && (
          <div className="w-full lg:w-1/2">
            <div className="relative w-full h-64 lg:h-[450px]">
              <Image
                src={imageSrc}
                alt={imageAlt}
                fill
                className="rounded-lg shadow-lg object-cover"
              />
            </div>
          </div>
        )}

        {/* Content */}
        <div className="w-full lg:w-1/2">{children}</div>

        {/* Image on the Right */}
        {!imageLeftPosition && (
          <div className="w-full lg:w-1/2">
            <div className="relative w-full h-64 lg:h-[450px]">
              <Image
                src={imageSrc}
                alt={imageAlt}
                fill
                className="rounded-lg shadow-lg object-cover"
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SectionWithImage;
