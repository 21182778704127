"use client";
import Image from "next/image";

const Intro = () => {
  return (
    <div>
      <section className="bg-black py-5 lg:py-8 ">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 md:gap-6 items-start justify-between text-center text-white">
            <div>
              <Image
                alt="Prime locations"
                src="/images/icons/ico_location.png"
                className=" object-cover m-auto mb-4"
                width={50}
                height={50}
              />
              <p className="font-medium">Prime locations</p>
            </div>
            <div>
              <Image
                alt="Premium Comfort & Amenities"
                src="/images/icons/ico-amenities.png"
                className=" object-cover m-auto mb-4"
                width={50}
                height={50}
              />
              <p className="font-medium">Premium Comfort & Amenities</p>
            </div>
            <div>
              <Image
                alt="Flexible Rental Terms"
                src="/images/icons/ico-rental.png"
                className=" object-cover m-auto mb-4"
                width={50}
                height={50}
              />
              <p className="font-medium">Flexible Rental Terms</p>
            </div>
            <div>
              <Image
                alt="Diverse Accommodation Options"
                src="/images/icons/ico-accomodation.png"
                className=" object-cover m-auto mb-4"
                width={50}
                height={50}
              />
              <p className="font-medium">Diverse Accommodation Options</p>
            </div>
            <div>
              <Image
                alt="Secure & Safe Living Spaces"
                src="/images/icons/ico_location.png"
                className=" object-cover m-auto mb-4"
                width={50}
                height={50}
              />
              <p className="font-medium">Secure & Safe Living Spaces</p>
            </div>
            {/* <div>
              <img
                alt=""
                src="/images/ico-luxury.png"
                className=" object-cover m-auto mb-4"
                width={50}
                height={50}
              />
              <p className="font-medium">Affordable Luxury</p>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
