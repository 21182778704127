"use client";
import Header from "@/components/Header";
import { useEffect, useState } from "react";

const StickyHeader = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlHeaderVisibility = () => {
    if (typeof window !== "undefined") {
      // Check the scroll position
      const currentScrollY = window.scrollY;

      if (currentScrollY > 200 && currentScrollY > lastScrollY) {
        setIsVisible(false); // Hide header
      } else {
        setIsVisible(true); // Show header
      }

      setLastScrollY(currentScrollY); // Update the last scroll position
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlHeaderVisibility, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", controlHeaderVisibility);
    };
  }, [lastScrollY]);

  return (
    <div
      className={`fixed top-0 z-[999] w-full transition-colors duration-300 font-bold tracking-wider bg-black/20 font-sans ${
        !isVisible && "-translate-y-full"
      }`}
    >
      <Header />
    </div>
  );
};

export default StickyHeader;
